import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  PostLoginRedirectService, AuthenticationService, AuthenticatedUser,
  ErrorHandlerService
} from '@aveva/connect-web-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private postLoginRedirect: PostLoginRedirectService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) {    
    this.translate.setDefaultLang('en');
    this.postLoginRedirect.watch();
    this.authenticationService.registerCallbackHandler(
      (user: AuthenticatedUser) => this.onAuthenticationCallbackSuccess(user),
      (error: Error) => this.onAuthenticationCallbackError(error));
  }

  private async onAuthenticationCallbackSuccess(user: AuthenticatedUser): Promise<any> {
    if (user.account) {
      this.router.navigate(['/'], { replaceUrl: true });
    } else {
      try {
        await this.errorHandler.handle(
          'callback.controller: Could not retrieve the user.account property',
          'accountDetailsNotAvailable');
      } catch { }
      await this.authenticationService.signout();
    }
  }

  private async onAuthenticationCallbackError(error: Error): Promise<any> {
    try {
      await this.errorHandler.handle(error, 'signin_failed');
    } catch { }
    await this.authenticationService.signout();
  }
}
