import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { AuthenticatedUser, SideNavLink } from '@aveva/connect-web-core';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  entGroup: any;
  /**
   * The authenticated connect user.
   * @member {ConnectUser}
   */
  user = new AuthenticatedUser();

  /**
   * The account data for the authenticated connect user.
   * @member {any}
   */
  account: any;

  // /**
  //  * The accounts data for the authenticated connect user.
  //  * @member {IAccount[]}
  //  */
  // accounts: IAccount[];

  // /**
  //  * The actor that represents this user.
  //  * @member {ProductDocActor}
  //  */
  // actors: ProductDocActor[];

  /**
   * The link to the account icon for this account from connect.
   * @member {string}
   */
  accountIcon: string;

  /**
* The side bar navigation links array.
* @member {SideNavLink[]}
*/
  links: SideNavLink[] = [];

  spinner: Boolean;

  actingAsAla = false;
  clientAccountName = '';
  clientCustomerCode = '';
  clientAccountId = '';

  routerEvents: Subscription;

  /**
   * Creates an instance of root component.
   * @param {ActivatedRoute} route          Used to obtain resolved data from the router.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  /**
   * Initialises the RootComponent data.
   */
  ngOnInit() {
    // Obtain data from the route.
    this.route.data
      .subscribe((data: { user: AuthenticatedUser,  account: any, accounts: any }) => {
        this.user = data.user;
        this.account = data.account;
        this.links=[];
        this.getIcon(this.account);
      });
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }

  

  /**
   * Obtains the link for the accounts icon.
   * @param {any} account The connect account data to use to read out the icon link.
   */
  private getIcon(account: any) {
    if (account && account._links && account._links.icon && account._links.icon.href) {
      account.one('icon').get().toPromise()
        .then((icon) => {
          this.accountIcon = 'data:image/png;base64,' + icon;
        })
        .catch((error) => console.error(error));
    }
  }
}
