<section
    class="doc-hero doc-hero--align-right"
    style="
        background: url('assets/img/LifeSciencesMasthead.png') no-repeat left
            top;
        background-size: cover;
    "
>
    <img
        src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoiYXZldmFcL2NvdmVycGFnZV9sb2dvc1wvMTZcLzZcL2MwNGViM2MwNWY2YTkxZWRjMWVkNDhhNjA4OGQ1YWExLTE1OTcwNjY0NjkucG5nIn0:aveva:azSGNbTVpASJs_NE1vzurGXQXhH-alGExGr3bGFJ1D0?width=800"
        alt="Aveva"
        class="doc-hero__logo"
    />
</section>
    <div class="home-container">
              <section class="mdc-layout-grid">
                  <div class="mdc-layout-grid__inner">
                      <mat-card
                          class="
                              mdc-layout-grid__cell
                              mdc-layout-grid__cell--span-4-tablet
                              mdc-layout-grid__cell--span-6-desktop
                          "
                          *ngFor="let data of HOME_DATA; let i = index"
                      >
                          <mat-card-header class="doc-card__header">
                              <img
                                  src="assets/img/ProductIcon.png"
                                  mat-card-avatar
                                  class="example-header-image"
                              />
                              <h1 mat-card-title>{{ data.title }}</h1>
                          </mat-card-header>
                          <mat-card-content>
                              <p class="doc-card__description">
                                  {{ data.description }}
                              </p>
                          </mat-card-content>
                          <mat-card-actions>
                            <uilab-button appearance="transparent" 
                            (click)="changeRoute(data.description)"
                            appearance="outlined"
                            >View all
                        </uilab-button>
                          </mat-card-actions>
                      </mat-card>
                  </div>
              </section>
  </div>