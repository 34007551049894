import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { ProductDocMaterialModule } from './productdoc.material.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatSortModule } from '@angular/material/sort';
import { ConnectCoreModule } from '@aveva/connect-web-core';
import { AccountResolverService } from './account/account-resolver.service';
import { ProductDocRoutingModule } from './productdoc-routing.module';
import { RootComponent } from './root/root.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';

@NgModule({
  imports: [
    CommonModule,
    ProductDocRoutingModule,
    ConnectCoreModule,
    BrowserModule,
    FormsModule,
    CdkTableModule,
    MatSortModule,
    ReactiveFormsModule,
    ProductDocMaterialModule    
  ],
  providers: [
    AccountResolverService,
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  declarations: [
    RootComponent,
    HomeComponent,
    ProductComponent
    
  ],
  entryComponents: []
})
export class ProductDocModule { }
