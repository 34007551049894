// Copyright 2016 to current year. AVEVA Solutions Limited and its subsidiaries. All rights reserved.

import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { CacheObservable } from '../cache_observable/cache_observable';
import { ErrorHandlerService, AuthenticationService, AuthenticatedUser } from '@aveva/connect-web-core';
import { from, Observable, EMPTY, merge, of } from 'rxjs';
import { catchError, filter, map, mergeAll, mergeMap, first, share, toArray } from 'rxjs/operators';


/**
 * Represents the category of user that we are serving.
 */
export type ProductDocActor =
  'End User';

@Injectable()
export class ActorService implements Resolve<ProductDocActor[]> {

  // Hold the resolved query for caching.
  @CacheObservable()
  public get actors(): Observable<ProductDocActor> {
    return from(this.authService.getUser()).pipe(
      map(u => this.get_actors_from_user(u)),
      catchError(e => this.handle_sign_in_failed(e)),
      mergeAll(),
      map(a => <ProductDocActor>a),
      share()
    );
  }

  /**
   * Constructs the Actor service component.
   * Uses injection to resolve all parameters.
   */
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private errorHandler: ErrorHandlerService
  ) { }

  /**
   * Provides an observable that contains the actors that represent the current user.
   */
  resolve(): Observable<ProductDocActor[]> {
    return this.actors.pipe(toArray());
  }

  /**
   * Obtains the actors that represents a ConnectUser.
   * @param user The connect user to check for permissions.
   */
  private get_actors_from_user(user: AuthenticatedUser): Observable<ProductDocActor> {
    if (user == null) {
      this.router.navigate(['./login'], { replaceUrl: true });
      return EMPTY;
    }

    return EMPTY;
  }

  /**
   * Deals with a sign in failure during resolve.
   * @param error The error that occurred.
   */
  private handle_sign_in_failed(error: any): Observable<ProductDocActor> {
    this.errorHandler.handle(error, 'signin_failed');
    return EMPTY;
  }

}
