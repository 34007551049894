import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ProductDialogContentComponent } from "./dialog/dialog.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: "doc-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent {
  UX_DATA: any = {};
  productData: any;
  productName = "";
  jsonResponse: any = {};
  versionSelectedIndex = null;
  productSelectedIndex: number | undefined;
  environment = window["environment"];
  parsedBearerKeyValue: any = JSON.parse(
    sessionStorage.getItem(
      "oidc.user:" +
        this.environment.authority +
        ":" +
        this.environment.clientId
    )
  );
  constructor(
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    let selectedDepartment = sessionStorage.getItem("selectedDept");
    this.router.navigate(["product/" + selectedDepartment]).then(() => {
      this.getUXFromBlob(selectedDepartment);
    });
  }
  productSelection(index: number): void {
    this.productSelectedIndex =
      this.productSelectedIndex == index ? undefined : index;
  }

  openDialog(selectedProduct: any, selectedVersion: any) {
    sessionStorage.setItem("selectedVersion", JSON.stringify(selectedVersion));
    sessionStorage.setItem("selectedTitle", selectedProduct.title);
    this.dialog.open(ProductDialogContentComponent);
  }
  getUXFromBlob(dept: any) {
    const headers = {
      Authorization: this.parsedBearerKeyValue["id_token"],
    };
    const requestOptions = {
      headers: new HttpHeaders(headers),
    };
    this.http
      .get(window["environment"]["blobUxJsonUrl"], requestOptions)
      .subscribe((data) => {
        this.jsonResponse = data;
        if (this.jsonResponse["response"] == "Success") {
          var uxJson = this.jsonResponse["json"];
          for (var department of uxJson) {
            if (department["description"] === dept)
              this.productData = department["products"];
            sessionStorage.setItem(
              "productData",
              JSON.stringify(this.productData)
            );
          }
        }
      });
  }
}
