import { NgModule, ReflectiveInjector } from '@angular/core';
import { Router, Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticatedUserResolverService, UserProfileComponent, UserProfileResolverService } from '@aveva/connect-web-core';

import { AccountResolverService } from './account/account-resolver.service';
import { RootComponent } from './root/root.component';
import { HomeComponent } from './home/home.component';
import { ActorService } from './actor/actor.service';
import { ProductComponent } from './product/product.component';

// Guards

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
      actors: ActorService,
    },

    children: [
      // Universal paths.
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'product/:id',
        component: ProductComponent
      },

      {
        path: 'home',
        component: HomeComponent
      },

      {
        path: 'me',
        component: UserProfileComponent,
        resolve: {
          user: UserProfileResolverService
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
  ]
})
export class  ProductDocRoutingModule { }
