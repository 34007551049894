<div class="dialog-container">
  <h1 mat-dialog-title>{{ this.productTitle }}</h1>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div>
      <p class="version-title">{{ this.data.releaseNumber }}</p>
      <li style="list-style: none; clear: both" *ngFor="let doc of obs | async">
        <p class="alignleft">{{ doc.linkTitle }}</p>
        <p class="alignright">
          <a target="_blank" [href]="doc.documentStoragePath">View</a><br>
        </p>
        <mat-divider></mat-divider>
      </li>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <uilab-button appearance="transparent" mat-button mat-dialog-close
      >Close</uilab-button
    >
  </mat-dialog-actions>
</div>
