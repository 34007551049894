<section
    class="doc-hero doc-hero--align-right"
    style="
        background: url('assets/img/LifeSciencesMasthead.png') no-repeat left
            top;
        background-size: cover;
    "
>
    <img
        src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoiYXZldmFcL2NvdmVycGFnZV9sb2dvc1wvMTZcLzZcL2MwNGViM2MwNWY2YTkxZWRjMWVkNDhhNjA4OGQ1YWExLTE1OTcwNjY0NjkucG5nIn0:aveva:azSGNbTVpASJs_NE1vzurGXQXhH-alGExGr3bGFJ1D0?width=800"
        alt="Aveva"
        class="doc-hero__logo"
    />
    <div class="doc-layout-container">
        <h1 class="doc-hero__title">{{ productName }}</h1>
    </div>
</section>

<div class="doc-layout-container">
    <section class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <mat-card
                class="
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-tablet
                    mdc-layout-grid__cell--span-6-desktop
                "
                *ngFor="let prod of productData; let in = index"
            >
                <mat-card-header>
                    <img
                        src="assets/img/ProductIcon.png"
                        mat-card-avatar
                        class="example-header-image"
                    />
                    <h1 mat-card-title>{{ prod.title }}</h1>
                </mat-card-header>
                <mat-card-content>
                    <!-- <h2 class="doc-card__subtitle" *ngIf="prod.formerly">
                        {{ prod.formerly }}
                    </h2> -->
                    <br>
                    <p class="doc-card__description">
                        {{ prod.description }}
                    </p>
                    <uilab-button
                        appearance="text"
                        (click)="productSelection(in)"
                        [class.doc-card__button--active]="
                            in === productSelectedIndex
                        "
                        >Versions
                        <uilab-icon
                            name="menu-down"
                            slot="trailing-icon"
                        >
                        <img
                        src="assets/img/menu-down.png"
                        class="img-size"
                    />
                </uilab-icon>
                        
                </uilab-button>
                    <section
                        [class.doc-card__hidden]="in !== productSelectedIndex"
                    >
                        <h2 class="doc-card__subtitle">Version</h2>
                        <a
                            class="doc-pill"
                            [class.doc-pill__success]="
                                i === versionSelectedIndex &&
                                in === productSelectedIndex
                            "
                            *ngFor="let version of prod.versions; let i = index"
                            (click)="openDialog(prod, version)"
                            >{{ version.releaseNumber }}</a
                        >
                    </section>
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</div>
