import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RESTANGULAR_AM, ErrorHandlerService, AuthenticationService } from '@aveva/connect-web-core';
import { Restangular } from 'ngx-restangular';

@Injectable()
export class AccountResolverService implements Resolve<any> {
  constructor(
    @Inject(RESTANGULAR_AM) private restangularAM: Restangular,
    private errorHandler: ErrorHandlerService,
    private authService: AuthenticationService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getUser()
      .then(user => {
        if (user.account === 'ConnectAdministration') {
          return {
            id: user.account,
            name: 'Connect Administration',
            plain: () => {
              return {
                id: user.account,
                name: 'Connect Administration'
              };
            }
          };
        }
        return this.restangularAM.one('accounts', user.account).get().toPromise()
          .catch((error) => this.errorHandler.handle(error, 'accountsNotAvailable'));
      });
  }
}
