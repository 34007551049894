// Copyright 2016 to current year. AVEVA Solutions Limited and its subsidiaries. All rights reserved.

import { Observable, from } from 'rxjs';
import { toArray, share } from 'rxjs/operators';

/**
 * Ensures that the given (somewhat hidden) property exists on the given object.
 * Used by CacheObservable to hide the cache values.
 * @param obj The object to check for the property on.
 * @param property The property name to check for.
 * @param default_value The default value of the property is not found.
 */
function touch_property(obj: Object, property: string) {
    if (!obj.hasOwnProperty(property)) {
        const opts = {
            value: null,
            configurable: false,
            enumerable: false,
            writable: true
        };

        Object.defineProperty(obj, property, opts);
    }
}

/**
 * A decorator that caches the result of a getter for an observable property.
 * The observable will have `share()` added to its pipe: https://rxjs-dev.firebaseapp.com/api/operators/share
 */
export function CacheObservable() {
    return (
        target: any,
        propertyKey: string,
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const get: () => Observable<any> = descriptor.get;
        const obs_key = `__cache_observable_${propertyKey}_obs`;
        const view_key = `__cache_observable_${propertyKey}_view`;

        descriptor.get = function () {
            touch_property(this, obs_key);
            touch_property(this, view_key);

            if (this[view_key] != null) {
                return from(this[view_key]);
            }

            if (this[obs_key] != null) {
                return this[obs_key];
            }

            this[obs_key] = get.apply(this).pipe(share());

            (<Observable<any>>this[obs_key]).pipe(toArray())
                .subscribe(data => this[view_key] = data);

            return this[obs_key];
        };
    };
}
