import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "doc-product-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class ProductDialogContentComponent implements OnInit, OnDestroy {
  jsonResponse: any = {};
  environment = window["environment"];
  data: any = JSON.parse(sessionStorage.getItem("selectedVersion"));
  productTitle: string = sessionStorage.getItem("selectedTitle");
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;
  dataSource: MatTableDataSource<GuidesData> =
    new MatTableDataSource<GuidesData>(this.data.guides);
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private http: HttpClient
  ) {
    // console.log(this.data);
  }
  ngOnInit() {
    this._MatPaginatorIntl.itemsPerPageLabel = "Rows per page";
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.obs = this.dataSource.connect();
    // console.log(this.obs);
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
export interface GuidesData {
  releaseNumber: string;
  guides: [];
}
