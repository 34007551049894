import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConnectCoreModule, ConnectCoreModuleConfig } from '@aveva/connect-web-core';

import { ProductDocModule } from './productdoc/productdoc.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RestangularModule } from 'ngx-restangular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {ActorService} from './productdoc/actor/actor.service'
let environment: any = {};
export let CoreModuleConfig: ConnectCoreModuleConfig = null;

environment = window['environment'];
CoreModuleConfig = {
  authentication: {    
    authority: environment.authority,
    clientId: environment.clientId,
    cachePrefix: 'ProductDoc_Web',
    singleSignOutUrl: environment.singleSignOutUrl
  },
  authorization: {
    cachePrefix: 'ProductDoc_Web'
  },
  connectApi: {
    logging: environment.apis.logging,
    registry: environment.apis.registry,
    am: environment.apis.am,
    scm: environment.apis.scm,
    uam: environment.apis.uamv2,
    assets: environment.apis.assets
  },
  components: {
    appHeaderTitle: 'Product Documentation',
    appHeaderLogoPath: '', // Path to a custom logo for your capability can be specified here.
    sideNavLogoPath: '/assets/imgs/AVEVA-Cloud_interimmark_rev01-01_black.svg',
    termsAndConditions: 'https://sw.aveva.com/legal',
    connectLink: environment.proddocConnect + '/#/home'
  }
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,    
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RestangularModule.forRoot(),
    ConnectCoreModule.forRoot(CoreModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ProductDocModule,    
    AppRoutingModule
    
  ],
  providers: [ActorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
