import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {
  HOME_DATA: any;
  environment = window['environment'];
  parsedBearerKeyValue :any = JSON.parse(sessionStorage.getItem("oidc.user:"+this.environment.authority +":"+this.environment.clientId));
  jsonResponse: any = {};
  constructor(private router: Router, private http: HttpClient) {
    this.getUXFromBlob();
  }

  changeRoute(id: string): void {
    // console.log('changeRoute', id);
    sessionStorage.setItem('selectedDept', id);
    this.router.navigate(['/product', id]);
  }
  getUXFromBlob() {
    const headers = {
      Authorization: this.parsedBearerKeyValue['id_token']
  }; 
    this.http
      .get(window['environment']['blobUxJsonUrl'],{ headers: headers })
      .subscribe((data) => {
        this.jsonResponse = data;
        if (this.jsonResponse['response'] == 'Success') {
          this.HOME_DATA = this.jsonResponse['json'];
        }
      });
  }
}